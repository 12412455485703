<script>
  // # # # # # # # # # # # # #
  //
  //  LANDING
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onDestroy, onMount, tick } from "svelte"
  import { fade } from "svelte/transition"
  import { loadData } from "../sanity.js"
  import Fuse from "fuse.js"
  import get from "lodash/get"

  // COMPONENTS
  import ListingItem from "../Components/ListingItem.svelte"
  import Footer from "../Components/Footer.svelte"
  import Metadata from "../Components/Metadata.svelte"

  // STORES
  import {
    savedPosts,
    location,
    filterTerm,
    searchTerm,
    returnFromPost,
    backgroundColor,
    textColor,
    hoverColor,
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
  } from "../stores.js"
  location.set("index")

  // COMPONENTS

  // *** PROPS
  export let term = ""

  // ** CONSTANTS
  const query = "*[_type == 'post'] | order(startDate desc)"

  const fuseOptions = {
    threshold: 0.3,
    keys: ["title", "location"],
  }

  const MAIN_CATEGORIES = ["group-show", "solo-show", "residency"]

  // VARIABLES
  let mainPosts = []
  let filteredPosts = []
  let upcomingPosts = []
  let onViewPosts = []
  let loadingText = 0
  let fuse = {}

  $: {
    if (term) {
      filterTerm.set(term)
    }
  }

  if ($savedPosts.length > 0) {
    fuse = new Fuse($savedPosts, fuseOptions)
    console.log("__ 1", "$savedPosts", $savedPosts)
    mainPosts = $savedPosts.filter(p =>
      get(p, "multiCategory", []).some(p => MAIN_CATEGORIES.includes(p)),
    )
    console.log("mainPosts", mainPosts)
    filteredPosts = mainPosts
    console.log("filteredPosts", filteredPosts)
  } else {
    loadData(query).then(posts => {
      savedPosts.set(posts)
      console.log("__ 2", "$savedPosts", $savedPosts)
      loadingText = $savedPosts.length
      fuse = new Fuse($savedPosts, fuseOptions)
      setTimeout(() => {
        // filteredPosts = $savedPosts
        mainPosts = $savedPosts.filter(p =>
          get(p, "multiCategory", []).some(p => MAIN_CATEGORIES.includes(p)),
        )
        console.log("mainPosts", mainPosts)
        filteredPosts = mainPosts
        console.log("filteredPosts", filteredPosts)
      }, 600)
    })
  }

  $: {
    // BACKGROUND COLOR
    backgroundColor.set($defaultBackgroundColor)
    // TEXT COLOR
    textColor.set($defaultTextColor)
    // HOVER COLOR
    hoverColor.set($defaultHoverColor)
  }

  // FILTER
  $: {
    if (term) {
      filteredPosts = []
      window.scrollTo({ top: 0 })
      let preFilter = term
        ? $savedPosts.filter(p => get(p, "multiCategory", []).includes(term))
        : $savedPosts
      loadingText = preFilter.length
      setTimeout(() => {
        filteredPosts = term
          ? $savedPosts.filter(p => get(p, "multiCategory", []).includes(term))
          : $savedPosts
      }, 600)
    }
  }

  // SEARCH
  $: {
    if ($searchTerm) {
      filteredPosts = []
      window.scrollTo({ top: 0 })
      let searchResults = []
      if (fuse.search) {
        searchResults = fuse.search($searchTerm).map(hit => hit.item)
      }
      loadingText = searchResults.length
      setTimeout(() => {
        filteredPosts = searchResults
      }, 600)
    }
  }

  $: if (filteredPosts) {
    upcomingPosts = filteredPosts.filter(p => p.upcoming)
    onViewPosts = filteredPosts.filter(p => p.onView)
    filteredPosts = filteredPosts.filter(p => !p.upcoming && !p.onView)
  }

  onMount(async () => {
    if ($returnFromPost) {
      const yOffset = window.matchMedia("(max-width: 700px)").matches
        ? -50
        : -80
      // await tick();
      setTimeout(() => {
        const targetPost = document.querySelector("#" + $returnFromPost)
        if (targetPost) {
          const y =
            targetPost.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset
          window.scrollTo({ top: y })
        }
        returnFromPost.set(false)
      }, 600)
    }
  })

  // *** ON MOUNT
  onDestroy(async () => {
    searchTerm.set(false)
  })
</script>

<Metadata />

<div class="landing" style="background: {$backgroundColor};">
  {#if filteredPosts.length > 0}
    <!-- UPCOMING -->
    {#if upcomingPosts.length > 0}
      <div class="section-divider">
        <div class="text">UPCOMING</div>
      </div>
      {#each upcomingPosts as p, index}
        <ListingItem {p} {index} />
      {/each}
      <div class="section-divider-end">
        <div class="line" />
      </div>
    {/if}
    <!-- ON VIEW -->
    {#if onViewPosts.length > 0}
      <div class="section-divider">
        <div class="text">ON VIEW</div>
      </div>
      {#each onViewPosts as p, index}
        <ListingItem {p} {index} />
      {/each}
      <div class="section-divider-end">
        <div class="line" />
      </div>
    {/if}

    {#each filteredPosts as p, index}
      <ListingItem {p} {index} />
    {/each}
  {:else}
    <div class="loading-text" in:fade>
      <strong>{loadingText}</strong>
      posts
    </div>
  {/if}
</div>

{#if filteredPosts.length > 0}
  <Footer />
{/if}

<style lang="scss">.landing {
  font-family: "haas", Helvetica, Arial, sans-serif;
  font-size: 61.2px;
  margin: 0;
  padding: 10px;
  padding-top: calc(80px - 0.2em);
  padding-bottom: 2em;
  line-height: 1em;
  min-height: 100vh;
  width: 100vw; }
  @media (max-width: 800px) {
    .landing {
      font-size: 28px;
      padding-top: 40px; } }

.loading-text {
  margin-top: 0.4em;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
  display: inline-block; }

.section-divider {
  margin-top: 0.4em;
  opacity: 0.4;
  width: 40ch; }

.section-divider-end {
  margin-top: 0.4em;
  opacity: 0.4;
  max-width: 40ch; }
  .section-divider-end .line {
    width: 100%;
    height: 2px;
    background: black; }</style>
