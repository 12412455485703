<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE ARTICLE
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onMount } from "svelte"
  import { fade } from "svelte/transition"
  import { urlFor, loadData, renderBlockText, toPlainText } from "../sanity.js"
  import { format, getYear } from "date-fns"
  import getVideoId from "get-video-id"

  // *** PROPS
  export let slug = ""

  // COMPONENTS
  import Footer from "../Components/Footer.svelte"
  import Metadata from "../Components/Metadata.svelte"

  // STORES
  import {
    location,
    menuBarText,
    backgroundColor,
    textColor,
    returnFromPost,
    hoverColor,
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
  } from "../stores.js"
  location.set("single")

  // ** CONSTANTS
  const query = "*[slug.current == $slug]{...}[0]"

  let post = loadData(query, { slug: slug })

  // FUNCTIONS
  const formattedDate = (start, end) => {
    if (!start) {
      return false
    }

    const startDate = Date.parse(start)

    if (!end) {
      return format(startDate, "dd.MM.yyyy")
    }

    const endDate = Date.parse(end)

    if (format(startDate, "dd.MM.yyyy") == format(endDate, "dd.MM.yyyy")) {
      return format(startDate, "dd.MM.yyyy")
    }

    const startFormat =
      getYear(startDate) == getYear(endDate) ? "dd.MM" : "dd.MM.yyyy"
    const endFormat = "dd.MM.yyyy"

    return format(startDate, startFormat) + " – " + format(endDate, endFormat)
  }

  post.then(p => {
    console.dir(p)
    if (p.backgroundColor) {
      backgroundColor.set(p.backgroundColor.hex)
    } else {
      backgroundColor.set($defaultBackgroundColor)
    }
    if (p.textColor) {
      textColor.set(p.textColor.hex)
    } else {
      textColor.set($defaultTextColor)
    }
    if (p.hoverColor) {
      hoverColor.set(p.hoverColor.hex)
    } else {
      hoverColor.set($defaultHoverColor)
    }
    returnFromPost.set(p.slug.current)
  })

  onMount(async () => {
    window.scrollTo({ top: 0 })
  })
</script>

{#await post then post}
  <Metadata {post} />

  <div
    class="single"
    style="background: {$backgroundColor}; color: {$textColor};"
  >
    <!-- TITLE -->

    <div class="meta">
      <span class="title">{post.title},</span>
      {#if formattedDate(post.startDate, post.endDate)}
        <span class="date">{formattedDate(post.startDate, post.endDate)},</span>
      {/if}
      {#if post.location}
        <span class="location">{post.location}</span>
      {/if}
      {#if post.description}
        <div class="description">
          {@html renderBlockText(post.description)}
        </div>
      {/if}
    </div>

    <!-- DESCRIPTION -->
    <!-- <div class="description" /> -->
    <!-- <div class="description" /> -->
    <script>
      function imageloaded(el) {
        el.parentElement.classList.add("loaded")
      }
    </script>
    <!-- MAIN CONTENT -->
    <div class="content">
      {#each post.content as c}
        <div class="content-item" class:loaded={c._type !== "image"}>
          {#if c._type === "videoEmbed"}
            <!-- // YOUTUBE -->
            {#if c.video.includes("youtube") || c.video.includes("youtu.be")}
              <!-- <div class="embed-container"> -->
              <iframe
                width="720px"
                height="480px"
                src={"https://www.youtube.com/embed/" + getVideoId(c.video).id}
                frameborder="no"
                allow="accelerometer; autoplay; encrypted-media; gyroscope;
                picture-in-picture"
                allowfullscreen
              />
              <!-- </div> -->
            {/if}
            <!-- // VIMEO -->
            {#if c.video.includes("vimeo")}
              <div class="embed-container">
                <iframe
                  width="720px"
                  height="480px"
                  src={"https://player.vimeo.com/video/" +
                    getVideoId(c.video).id}
                  frameborder="no"
                  byline="faslse"
                  color="#ffffff"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                />
              </div>
            {/if}
          {/if}
          {#if c._type === "audioEmbed"}
            <!-- // SOUNDCLOUD -->
            {#if c.audio.includes("soundcloud")}
              <div class="soundcloud-container">
                <iframe
                  width="100%"
                  height="300"
                  src={"https://w.soundcloud.com/player/?url=" +
                    c.audio +
                    "&color=%23fffff"}
                  frameborder="no"
                  scrolling="no"
                  allow="autoplay"
                />
              </div>
            {/if}
          {/if}
          {#if c._type === "videoFile"}
            <video
              src={"https://cdn.sanity.io/files/imiuzplw/production/" +
                c.video.asset._ref.replace("file-", "").replace("-mp4", ".mp4")}
              controls
              loop
              autoplay
            />
          {/if}
          {#if c._type === "audioFile"}
            <audio
              src={"https://cdn.sanity.io/files/imiuzplw/production/" +
                c.audio.asset._ref.replace("file-", "").replace("-mp3", ".mp3")}
              controls
              loop
            />
          {/if}
          {#if c._type === "image"}
            {#if c.asset}
              <img
                onload="imageloaded(this)"
                alt={c.caption ? toPlainText(c.caption) : post.title}
                src={urlFor(c.asset)
                  .width(1200)
                  .quality(100)
                  .auto("format")
                  .url()}
              />
            {/if}
          {/if}
          {#if c.caption}
            <div class="caption">
              {@html renderBlockText(c.caption)}
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>

  <Footer />
{/await}

<style lang="scss">.single {
  font-size: 61.2px;
  margin: 0;
  padding: 5px;
  width: 100vw;
  padding-top: calc(80px - 0.2em); }
  @media (max-width: 800px) {
    .single {
      font-size: 28px;
      padding-top: calc(50px - 0.2em); } }

.meta {
  font-size: 61.2px;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 0.4em;
  margin-top: 0.4em;
  line-height: 1em;
  font-family: "haas", Helvetica, Arial, sans-serif;
  margin-left: 5px;
  opacity: 1;
  transition: opacity cubic-bezier(0.23, 1, 0.32, 1); }
  @media (max-width: 800px) {
    .meta {
      font-size: 28px;
      margin-top: 0.5em; } }
  .meta .title {
    font-weight: 600; }

.description {
  font-size: 43.2px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1em;
  max-width: 90%;
  font-family: "haas", Helvetica, Arial, sans-serif;
  opacity: 1;
  max-width: 90%; }
  @media (max-width: 800px) {
    .description {
      font-size: 28px; } }

.caption {
  font-size: 11.4px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 32px;
  margin-top: 5px;
  line-height: 1em;
  max-width: 90%;
  width: 70ch;
  font-family: "haas", Helvetica, Arial, sans-serif;
  opacity: 1;
  letter-spacing: 1px;
  transition: opacity cubic-bezier(0.23, 1, 0.32, 1); }
  @media (max-width: 800px) {
    .caption {
      font-size: 9.5px; } }

.content-item {
  font-size: 61.2px;
  line-height: 1em;
  margin-bottom: 0.5em; }
  @media (max-width: 800px) {
    .content-item {
      font-size: 28px;
      margin-bottom: 0.5em; } }
  .content-item img {
    display: block;
    width: 100%;
    max-height: 90vh;
    object-fit: contain;
    object-position: left; }</style>
