<script>
  // # # # # # # # # # # # # #
  //
  //  gawedakulbokaite.com
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { Router, Route } from "svelte-routing"
  import { loadData } from "./sanity.js"

  // POLYFILL
  import smoothscroll from "smoothscroll-polyfill"
  smoothscroll.polyfill()

  // STORES
  import {
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
    backgroundColor,
    textColor,
    hoverColor,
    aboutContent,
    aboutDownloads,
    aboutContact,
  } from "./stores.js"

  $: {
    document.getElementsByTagName("BODY")[0].style.background = $backgroundColor
    document.documentElement.style.background = $backgroundColor
  }

  // COMPONENTS
  import Footer from "./Components/Footer.svelte"
  import MenuBar from "./Components/MenuBar.svelte"

  // ROUTES
  import Landing from "./Routes/Landing.svelte"
  import Single from "./Routes/Single.svelte"
  import About from "./Routes/About.svelte"
  import Error404 from "./Routes/Error404.svelte"

  const landingQuery = "*[_id == '7c7e3fd6-629b-47d9-93b0-b496f778f032'][0]"

  let aboutPost = loadData(landingQuery)

  aboutPost.then(a => {
    // ABOUT CONTENT
    aboutContent.set(a.content)
    // ABOUT DOWNLOADS
    aboutDownloads.set(a.downloads)
    // ABOUT CONTACT
    aboutContact.set(a.contact)
    // BACKGROUND COLOR
    if (a.backgroundColor) {
      defaultBackgroundColor.set(a.backgroundColor.hex)
    }
    // TEXT COLOR
    if (a.textColor) {
      defaultTextColor.set(a.textColor.hex)
    }
    // HOVER COLOR
    if (a.hoverColor) {
      defaultHoverColor.set(a.hoverColor.hex)
    }
  })
</script>

<MenuBar />

<Router>
  <Route path="/" component={Landing} title="landing" />
  <Route path="/about" component={About} title="about" />
  <Route path="/filter/:term" component={Landing} title="landing" />
  <Route path="/:slug" component={Single} title="single" />
  <Route component={Error404} title="404" />
</Router>

<style lang="scss" global>/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
:global(.flickity-enabled) {
  position: relative; }

:global(.flickity-enabled:focus) {
  outline: none; }

:global(.flickity-viewport) {
  overflow: hidden;
  position: relative;
  height: 100%; }

:global(.flickity-slider) {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
:global(.flickity-enabled.is-draggable) {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

:global(.flickity-enabled.is-draggable) :global(.flickity-viewport) {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

:global(.flickity-enabled.is-draggable) :global(.flickity-viewport.is-pointer-down) {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
:global(.flickity-button) {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

:global(.flickity-button:hover) {
  background: white;
  cursor: pointer; }

:global(.flickity-button:focus) {
  outline: none;
  box-shadow: 0 0 0 5px #19f; }

:global(.flickity-button:active) {
  opacity: 0.6; }

:global(.flickity-button:disabled) {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

:global(.flickity-button-icon) {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
:global(.flickity-prev-next-button) {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

:global(.flickity-prev-next-button.previous) {
  left: 10px; }

:global(.flickity-prev-next-button.next) {
  right: 10px; }

/* right to left */
:global(.flickity-rtl) :global(.flickity-prev-next-button.previous) {
  left: auto;
  right: 10px; }

:global(.flickity-rtl) :global(.flickity-prev-next-button.next) {
  right: auto;
  left: 10px; }

:global(.flickity-prev-next-button) :global(.flickity-button-icon) {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
:global(.flickity-page-dots) {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

:global(.flickity-rtl) :global(.flickity-page-dots) {
  direction: rtl; }

:global(.flickity-page-dots) :global(.dot) {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

:global(.flickity-page-dots) :global(.dot.is-selected) {
  opacity: 1; }

:global(html),
:global(body) {
  padding: 0;
  margin: 0;
  background: white; }

:global(p) {
  margin: 0; }

:global(*) {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(220, 220, 220, 0.2); }
  :global(*::selection) {
    background: rgba(220, 220, 220, 0.3);
    /* WebKit/Blink Browsers */ }
  :global(*::-moz-selection) {
    background: rgba(220, 220, 220, 0.3);
    /* Gecko Browsers */ }

:global(.meta) :global(p) {
  display: inline; }

:global(.about) :global(a) {
  font-weight: 600;
  text-decoration: none;
  color: inherit; }
  :global(.about) :global(a:hover) {
    text-decoration: underline; }

:global(a) {
  color: inherit;
  font-weight: bold;
  text-decoration: none; }
  :global(a:hover) {
    text-decoration: underline; }

:global(.content-item) {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
  :global(.content-item.loaded) {
    opacity: 1; }
  :global(.content-item) :global(video) {
    width: 720px;
    max-width: 100%; }

:global(.embed-container) {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 720px;
  width: 100%;
  margin-bottom: 1em;
  background: red; }
  :global(.embed-container) :global(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

:global(.soundcloud-container) {
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  margin-bottom: 1em; }
  :global(.soundcloud-container) :global(iframe) {
    width: 100%;
    height: 100%;
    border: 0; }</style>
