<script>
  // # # # # # # # # # # # # #
  //
  //  LISTING ITEM
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { links } from "svelte-routing";
  import { format, getYear } from "date-fns";
  import { urlFor } from "../sanity.js";
  import sample from "lodash/sample";
  import { fade } from "svelte/transition";
  import get from "lodash/get";

  // COMPONENTS
  import Typer from "./Typer.svelte";

  // *** PROPS
  export let p = {};
  export let index = 0;

  // STORES
  import {
    menuBarText,
    backgroundColor,
    textColor,
    hoverColor
  } from "../stores.js";

  // DOM REFS
  let sentinel = {};
  let imgEl = {};

  let hovered = false;

  // CONSTANTS
  const leftPositions = ["5%", "10%", "15%", "20%", "30%", "35%", "40%"];
  const rightPositions = [
    "5%",
    "10%",
    "15%",
    "20%",
    "30%",
    "35%",
    "40%",
    "45%",
    "50%"
  ];

  const topPositions = ["0%", "5%", "10%", "15%", "20%"];
  const widthList = ["200px", "250px", "300px", "350px"];

  // FUNCTIONS
  const formattedDate = (start, end) => {
    if (!start) {
      return false;
    }

    const startDate = Date.parse(start);

    if (!end) {
      return format(startDate, "dd.MM.yyyy");
    }

    const endDate = Date.parse(end);

    if (format(startDate, "dd.MM.yyyy") == format(endDate, "dd.MM.yyyy")) {
      return format(startDate, "dd.MM.yyyy");
    }

    const startFormat =
      getYear(startDate) == getYear(endDate) ? "dd.MM" : "dd.MM.yyyy";
    const endFormat = "dd.MM.yyyy";

    return format(startDate, startFormat) + " – " + format(endDate, endFormat);
  };

  const yearClass = d => {
    const parsedDate = Date.parse(d);
    return "year-" + getYear(parsedDate);
  };

  // const observer = new IntersectionObserver(
  //   entries => {
  //     entries.forEach(entry => {
  //       if (entry.intersectionRatio > 0) {
  //         menuBarText.set(format(Date.parse(p.startDate), "yyyy"));
  //         // menuBarText.set(getYear(Date.parse(p.startDate)));
  //         window.setTimeout(() => {
  //           menuBarText.set(false);
  //         }, 5000);
  //       }
  //     });
  //   },
  //   { threshold: 0.5 }
  // );

  // // *** ON MOUNT
  // onMount(async () => {
  // observer.observe(sentinel);
  // imgEl.top = sample(topPositions);
  // imgEl.left = sample(leftPositions);
  // });
</script>

<style lang="scss">.link {
  font-family: "haas", Helvetica, Arial, sans-serif;
  font-size: 61.2px;
  line-height: 1em;
  margin-top: 0.4em;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
  max-width: 40ch;
  display: inline-block;
  position: relative; }
  @media (max-width: 800px) {
    .link {
      font-size: 28px; } }
  .link a {
    color: black;
    text-decoration: none; }
    .link a:hover {
      color: white; }
      @media (max-width: 800px) {
        .link a:hover {
          color: inherit; } }
  .link .title {
    font-weight: 600; }
  .link img {
    position: absolute;
    margin-left: 10px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 100; }
    @media (max-width: 800px) {
      .link img {
        opacity: 1;
        position: static;
        display: block;
        pointer-events: all;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        margin-left: auto;
        margin-right: auto;
        max-height: 40vh;
        object-fit: cover; } }
  .link:hover img {
    opacity: 1; }

.outlink {
  position: absolute;
  pointer-events: none;
  opacity: 1;
  font-size: 25.2px;
  transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  font-family: monospace;
  z-index: 100;
  top: 20px;
  left: 40px;
  line-height: 1em;
  padding: 5px;
  font-weight: normal;
  letter-spacing: 1px;
  max-width: 50ch;
  word-break: break-all; }
  @media (max-width: 800px) {
    .outlink {
      display: none; } }

.location,
.date {
  font-weight: normal; }</style>

<div
  class="link {get(p, 'multiCategory', []).join(' ')}
  {yearClass(p.startDate)}"
  id={p.slug.current}
  in:fade={{ duration: 500 }}
  class:external={p.external}
  bind:this={sentinel}
  use:links>
  {#if p.external}
    <a
      href={p.externalLink}
      target="_blank"
      rel="noreferrer"
      on:mouseenter={e => {
        hovered = true;
      }}
      on:mouseleave={e => {
        hovered = false;
      }}>
      <span class="title">{p.title},</span>
      {#if formattedDate(p.startDate, p.endDate)}
        <span class="date">
          {formattedDate(p.startDate, p.endDate)}
          {#if p.location},{/if}
        </span>
      {/if}
      {#if p.location}
        <span class="location">{p.location}</span>
        {#if hovered}
          <span class="outlink" style="background: {$backgroundColor};">
            <Typer text={p.externalLink} />
          </span>
        {/if}
      {/if}
    </a>
  {:else}
    <a href={'/' + p.slug.current}>

      <span class="title">{p.title},</span>
      {#if formattedDate(p.startDate, p.endDate)}
        <span class="date">
          {formattedDate(p.startDate, p.endDate)}
          {#if p.location},{/if}
        </span>
      {/if}
      {#if p.location}
        <span class="location">{p.location}</span>
      {/if}
      {#if p.content && p.content[0]}
        <img
          in:fade={{ duration: 500, delay: 200 * index }}
          style="top:{sample(topPositions)}; right:{sample(rightPositions)};"
          alt={p.title}
          width={sample(widthList)}
          bind:this={imgEl}
          src={urlFor(p.content[0].asset)
            .width(400)
            .quality(100)
            .auto('format')
            .url()} />
      {/if}
    </a>
  {/if}
</div>
