<script>
  // # # # # # # # # # # # # #
  //
  //  ABOUT
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { navigate } from "svelte-routing"
  import { renderBlockText } from "../sanity.js"

  // STORES
  import {
    backgroundColor,
    textColor,
    hoverColor,
    aboutContent,
    returnFromPost,
    aboutDownloads,
    aboutContact,
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
  } from "../stores.js"

  returnFromPost.set(false)

  // COMPONENTS
  import Metadata from "../Components/Metadata.svelte"

  $: {
    // BACKGROUND COLOR
    if (!$backgroundColor) {
      backgroundColor.set($defaultBackgroundColor)
    }
    // TEXT COLOR
    if (!$textColor) {
      textColor.set($defaultTextColor)
    }
    // HOVER COLOR
    if (!$hoverColor) {
      hoverColor.set($defaultHoverColor)
    }
  }
</script>

<Metadata />

<div class="about" style="background: {$backgroundColor}; color: {$textColor};">
  <span
    class="menu-item close"
    on:click={e => {
      navigate("/")
    }}
    style="background: {$backgroundColor}; color: {$textColor};"
  >
    ×
  </span>

  <div class="inner">
    <!-- TEXT -->
    {#if $aboutContent}
      <div class="about-text">
        {@html renderBlockText($aboutContent)}
      </div>
    {/if}

    <!-- CONTACT -->
    <div class="about-contact">
      <a href={"mailto:" + $aboutContact}>>> CONTACT</a>
    </div>

    <!-- DOWNLOADS -->
    {#if $aboutDownloads}
      <div class="about-downloads">
        {#each $aboutDownloads as download}
          <a
            class="download-link"
            href={"https://cdn.sanity.io/files/imiuzplw/production/" +
              download.file.asset._ref
                .replace("file-", "")
                .replace("-pdf", ".pdf")}
            download
            target="_blank"
          >
            >> {download.text}
          </a>
        {/each}
      </div>
    {/if}

    <!-- MAILING LIST -->
    <!-- Begin Mailchimp Signup Form -->
    <div class="about-mailing-list">
      <!-- <link
        href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css" /> -->
      <!-- <style type="text/css">
        #mc_embed_signup {
          background: #fff;
          clear: left;
          font: 14px Helvetica, Arial, sans-serif;
        }
        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      </style> -->
      <div id="mc_embed_signup">
        <form
          action="https://gawedakulbokaite.us10.list-manage.com/subscribe/post?u=ea4c1820df6685806c242dd69&amp;id=a4ae27c3ff"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <p>
              <strong>Sign up to the Mailing list</strong>
            </p>
            <div class="mc-field-group">
              <input
                type="email"
                value=""
                name="EMAIL"
                placeholder="EMAIL ADDRESS"
                class="required email"
                id="mce-EMAIL"
              />
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display:none"
              />
              <div
                class="response"
                id="mce-success-response"
                style="display:none"
              />
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
              <input
                type="text"
                name="b_ea4c1820df6685806c242dd69_a4ae27c3ff"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
        </form>
      </div>
      <!-- <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js" ✂prettier:content✂="CgogICAgICA=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=">{}</script>      <script type="text/javascript">
        (function($) {
          window.fnames = new Array();
          window.ftypes = new Array();
          fnames[0] = "EMAIL";
          ftypes[0] = "email";
        })(jQuery);
        var $mcj = jQuery.noConflict(true);
      </script> -->
    </div>

    <!--End mc_embed_signup-->

    <!-- <div class="about-mailing-list">
      <div id="mc_embed_signup">
        <form
          action="https://arielfeminisms.us18.list-manage.com/subscribe/post?u=24e9e8374cfc64d3e1c33f6f4&amp;id=cd9f919375"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="inner validate"
          target="_blank"
          novalidate>
          <div id="mc_embed_signup_scroll">
            <p>
              <strong>Sign up to Mailing list</strong>
            </p>

            <div class="mc-field-group">
              <input
                type="email"
                value=""
                placeholder="Email address"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL" />
            </div>

            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display:none" />
              <div
                class="response"
                id="mce-success-response"
                style="display:none" />
            </div>

            <div style="position: absolute; left: -5000px;" aria-hidden="true">
              <input
                type="text"
                name="b_24e9e8374cfc64d3e1c33f6f4_cd9f919375"
                tabindex="-1"
                value="" />
            </div>

            <div class="clear">
              <input
                type="submit"
                value=">> Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button" />
            </div>

          </div>
        </form>
      </div>

    </div> -->
  </div>
</div>

<style lang="scss">.close {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 80px;
  width: 60px;
  font-size: 72px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  line-height: calc(80px - 10px); }
  .close:hover {
    color: white !important;
    cursor: pointer; }
  .close:active {
    color: white !important; }
  @media (max-width: 800px) {
    .close {
      font-size: 40px;
      height: 50px;
      line-height: 50px;
      line-height: calc(50px - 2px);
      padding-left: 5px;
      width: 40px; }
      .close:hover {
        color: inherit; } }

.about {
  font-size: 43.2px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  font-family: "haas", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1em;
  padding-left: 10px;
  overflow: hidden;
  display: block;
  overflow-y: auto;
  padding-bottom: 3.2em; }
  @media (max-width: 800px) {
    .about {
      font-size: 23.4px; } }
  .about .inner {
    max-width: calc(100% - 70px);
    padding-top: 20px; }
    @media (max-width: 800px) {
      .about .inner {
        max-width: calc(100% - 30px); } }
    .about .inner p {
      margin-bottom: 0.4em; }
  .about .about-text {
    margin-bottom: 0.8em;
    line-height: 1em; }
  .about .about-contact {
    margin-bottom: 0.8em; }
  .about .about-downloads {
    margin-bottom: 1.6em; }
    .about .about-downloads .download-link {
      display: block;
      text-transform: uppercase; }

#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 0; }

#mc_embed_signup .response {
  font-weight: bold;
  padding-bottom: 0.4em; }

#mc_embed_signup input[type="email"] {
  font-family: "haas", Helvetica, Arial, sans-serif;
  font-size: 43.2px;
  font-weight: 600;
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 4px solid black;
  outline: 0;
  padding: 0px;
  height: 1.2em;
  width: 26ch;
  max-width: 90%; }
  @media (max-width: 800px) {
    #mc_embed_signup input[type="email"] {
      font-size: 23.4px;
      border-bottom: 2px solid black; } }
  #mc_embed_signup input[type="email"]::placeholder {
    color: inherit; }

#mc_embed_signup input[type="submit"] {
  font-family: "haas", Helvetica, Arial, sans-serif;
  font-size: 43.2px;
  font-weight: 600;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  text-transform: uppercase;
  height: 1.2em;
  padding-left: 0px;
  color: inherit;
  cursor: pointer; }
  @media (max-width: 800px) {
    #mc_embed_signup input[type="submit"] {
      font-size: 23.4px; } }
  #mc_embed_signup input[type="submit"]:hover {
    text-decoration: underline; }

#mc_embed_signup .mc-field-group {
  padding-bottom: 0.4em; }</style>
