<script>
  // # # # # # # # # # # # # #
  //
  //  ERROR 404
  //
  // # # # # # # # # # # # # #

  // STORES
  import {
    backgroundColor,
    textColor,
    hoverColor,
    aboutContent,
    aboutDownloads,
    aboutContact,
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
  } from "../stores.js"

  $: {
    // BACKGROUND COLOR
    backgroundColor.set($defaultBackgroundColor)
    // TEXT COLOR
    textColor.set($defaultTextColor)
    // HOVER COLOR
    hoverColor.set($defaultHoverColor)
  }
</script>

<svelte:head>
  <title>404</title>
</svelte:head>

<div class="placeholder">
  <strong>404:</strong>
  Page not found
</div>

<style lang="scss">.placeholder {
  margin-top: 80px;
  padding-top: 0.4em;
  font-size: 61.2px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1em;
  font-family: "haas", Helvetica, Arial, sans-serif;
  margin-left: 10px;
  min-height: 80vh; }
  @media (max-width: 800px) {
    .placeholder {
      font-size: 28px; } }</style>
