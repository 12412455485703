<script>
  // # # # # # # # # # # # # #
  //
  //  MENUBAR
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { onMount } from "svelte"
  import { navigate, links } from "svelte-routing"
  import Flickity from "flickity"

  // STORES
  import {
    menuBarText,
    location,
    filterTerm,
    searchTerm,
    backgroundColor,
    textColor,
    hoverColor,
  } from "../stores.js"

  // DOM REFERENCES
  let menuContainer = {}
  let singleMenuContainer = {}
  let filterContainer = {}
  let timeContainer = {}
  let searchInput = {}

  $: {
    if (searchActive == true) {
      searchInput.focus()
    }
  }

  // VARIABLES
  let titleActive = true
  let menuActive = false
  let singleMenuActive = false
  let filterActive = false
  let timeActive = false
  let searchActive = false
  let searchInputValue = ""

  let menuSlide = {}
  let singleMenuSlide = {}
  let filterSlide = {}
  let timeSlide = {}

  function getYearsBetween(startYear, endYear) {
    var years = []
    for (var year = startYear; year <= endYear; year++) {
      years.push(year.toString())
    }
    return years
  }

  // CONSTANTS
  const filterCategories = [
    {
      title: "SOLO",
      name: "solo-show",
    },
    {
      title: "GROUP",
      name: "group-show",
    },
    {
      title: "PERFORMANCE/EVENT",
      name: "performance",
    },
    // {
    //   title: "YGRG",
    //   name: "ygrg"
    // },
    // {
    //   titlde: "TALK/EVENT",
    //   name: "talk-event"
    // },
    {
      title: "RESIDENCY/AWARD",
      name: "residency",
    },
    // {
    //   title: "ONLINE",
    //   name: "online"
    // },
    {
      title: "PRESS",
      name: "press",
    },
  ]
  const yearList = getYearsBetween(2015, new Date().getFullYear()).reverse()
  const sliderSettings = {
    freeScroll: true,
    prevNextButtons: false,
    pageDots: false,
    cellAlign: "left",
    contain: true,
    freeScrollFriction: 0.045,
  }

  $: {
    if ($location == "single") {
      menuActive = false
      singleMenuActive = false
      filterActive = false
      timeActive = false
      searchActive = false
      titleActive = true
    }
    if ($location == "index") {
      menuActive = false
      singleMenuActive = false
      titleActive = true
    }
  }

  // *** ON MOUNT
  onMount(async () => {
    menuSlide = new Flickity(menuContainer, sliderSettings)
    singleMenuSlide = new Flickity(singleMenuContainer, sliderSettings)
    filterSlide = new Flickity(filterContainer, sliderSettings)
    timeSlide = new Flickity(timeContainer, sliderSettings)

    if ($filterTerm) {
      titleActive = false
      menuActive = false
      filterActive = true
      filterSlide.select(
        filterCategories.findIndex(f => f.name === $filterTerm),
      )
      filterSlide.resize()
    }

    menuSlide.on(
      "staticClick",
      function (event, pointer, cellElement, cellIndex) {
        menuSlide.resize()

        if (cellElement.dataset.link == "about") {
          navigate("/about")
        } else if (cellElement.dataset.link == "filter") {
          menuActive = false
          filterActive = true
          filterSlide.resize()
        } else if (cellElement.dataset.link == "time") {
          menuActive = false
          timeActive = true
          timeSlide.resize()
        } else if (cellElement.dataset.link == "search") {
          menuActive = false
          searchActive = true
        }
      },
    )

    singleMenuSlide.on(
      "staticClick",
      function (event, pointer, cellElement, cellIndex) {
        if (cellElement.dataset.link == "about") {
          navigate("/about")
        }
      },
    )

    filterSlide.on(
      "staticClick",
      function (event, pointer, cellElement, cellIndex) {
        if (cellElement.dataset.name == $filterTerm) {
          navigate("/")
        } else {
          navigate("/filter/" + cellElement.dataset.name)
        }
      },
    )

    timeSlide.on(
      "staticClick",
      function (event, pointer, cellElement, cellIndex) {
        const yOffset = window.matchMedia("(max-width: 700px)").matches
          ? -50
          : -80
        const firstOfYear = document.querySelector(
          ".year-" + cellElement.dataset.year,
        )
        if (firstOfYear) {
          const y =
            firstOfYear.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset
          window.scrollTo({ top: y, behavior: "smooth" })
        }
      },
    )
  })
</script>

<div
  class="bar"
  use:links
  style="background: {$backgroundColor}; color: {$textColor};"
>
  <!-- TITLE -->
  <div class="title" class:active={titleActive}>
    <span
      class="inner"
      on:click={e => {
        titleActive = false
        if ($location == "single") {
          singleMenuActive = true
          singleMenuSlide.resize()
        } else {
          menuActive = true
          menuSlide.resize()
        }
      }}
    >
      GAWĘDA KULBOKAITĖ
    </span>
  </div>
  <!-- SINGLE MENU -->
  <div class="menu" class:active={singleMenuActive}>
    <div class="menu-slide slider" bind:this={singleMenuContainer}>
      <a class="menu-item" href="/">&lt;&lt; INDEX</a>
      <span class="menu-item" data-link="about">ABOUT</span>
    </div>
    <!-- CLOSE: MENU -->
    <span
      class="close"
      on:click={e => {
        singleMenuActive = false
        titleActive = true
      }}
      style="background: {$backgroundColor}; color: {$textColor}; box-shadow:
      -30px 0px 10px {$backgroundColor};"
    >
      ×
    </span>
  </div>
  <!-- MENU -->
  <div class="menu" class:active={menuActive}>
    <div class="menu-slide slider" bind:this={menuContainer}>
      <span class="menu-item" data-link="about">ABOUT</span>
      <span class="menu-item" data-link="filter">FILTER</span>
      <span class="menu-item" data-link="time">YEAR</span>
      <span class="menu-item" data-link="search">SEARCH</span>
      <span class="menu-item dummy">&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
    <!-- CLOSE: MENU -->
    <span
      class="close"
      on:click={e => {
        menuActive = false
        titleActive = true
      }}
      style="background: {$backgroundColor}; color: {$textColor}; box-shadow:
      -30px 0px 10px {$backgroundColor};"
    >
      ×
    </span>
  </div>
  <!-- FILTER -->
  <div class="filter" class:active={filterActive}>
    <div class="filter-slide slider" bind:this={filterContainer}>
      {#each filterCategories as cat, index}
        <span
          class="menu-item"
          data-name={cat.name}
          class:selected={cat.name == $filterTerm}
        >
          {cat.title}
        </span>
      {/each}
      <span class="menu-item dummy">&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
    <!-- CLOSE: FILTER -->
    <span
      class="close"
      on:click={e => {
        filterActive = false
        menuActive = true
        navigate("/")
      }}
      style="background: {$backgroundColor}; color: {$textColor}; box-shadow:
      -30px 0px 10px {$backgroundColor};"
    >
      ×
    </span>
  </div>
  <!-- TIMELINE -->
  <div class="timeline" class:active={timeActive}>
    <div class="time-slide slider" bind:this={timeContainer}>
      {#each yearList as year}
        <span class="menu-item" data-year={year}>{year}</span>
      {/each}
      <span class="menu-item dummy">&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
    <!-- CLOSE: TIME -->
    <span
      class="close"
      on:click={e => {
        timeActive = false
        menuActive = true
      }}
      style="background: {$backgroundColor}; color: {$textColor}; box-shadow:
      -30px 0px 10px {$backgroundColor};"
    >
      ×
    </span>
  </div>
  <!-- SEARCH -->
  <div class="search" class:active={searchActive}>
    <input
      bind:this={searchInput}
      bind:value={searchInputValue}
      on:keyup={e => {
        if (e.keyCode === 13) {
          searchTerm.set(searchInputValue)
        }
      }}
    />
    <!-- CLOSE: SEARCH -->
    <span
      class="close"
      on:click={e => {
        searchTerm.set(false)
        searchActive = false
        menuActive = true
        searchInputValue = ""
        navigate("/")
      }}
      style="background: {$backgroundColor}; color: {$textColor}; box-shadow:
      -30px 0px 10px {$backgroundColor};"
    >
      ×
    </span>
  </div>
</div>

<style lang="scss">.close {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 80px;
  width: 50px;
  font-size: 72px;
  font-weight: 600;
  padding-right: 10px;
  line-height: calc(80px - 5px); }
  .close:hover {
    color: white !important;
    cursor: pointer; }
  .close:active {
    color: white !important; }
  @media (max-width: 800px) {
    .close {
      font-size: 40px;
      height: 50px;
      line-height: calc(50px - 2px);
      padding-left: 5px;
      width: 40px; }
      .close:hover {
        color: inherit; } }

.bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  font-family: "haas", Helvetica, Arial, sans-serif;
  font-size: 61.2px;
  font-weight: 600;
  overflow: hidden;
  user-select: none;
  width: 100vw;
  height: 80px;
  line-height: 80px; }
  @media (max-width: 800px) {
    .bar {
      font-size: 28px;
      height: 50px;
      line-height: 50px; } }
  .bar .title {
    display: block;
    opacity: 0;
    pointer-events: none;
    padding-left: 10px;
    white-space: nowrap; }
    .bar .title.active {
      opacity: 1;
      pointer-events: all;
      overflow-x: scroll; }
    .bar .title .inner:hover {
      color: white;
      cursor: pointer; }
    .bar .title .inner:active {
      color: white; }
  .bar .menu,
  .bar .filter,
  .bar .timeline,
  .bar .search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: block;
    opacity: 0;
    padding-left: 0px;
    pointer-events: none; }
    .bar .menu.active,
    .bar .filter.active,
    .bar .timeline.active,
    .bar .search.active {
      opacity: 1;
      pointer-events: all; }
  .bar .search {
    padding-left: 10px; }
    .bar .search input {
      font-family: "haas", Helvetica, Arial, sans-serif;
      font-size: 61.2px;
      font-weight: 600;
      background: transparent;
      border: 0;
      border-radius: 0;
      border-bottom: 6px solid black;
      outline: 0;
      text-transform: uppercase;
      padding: 0px;
      height: 65px; }
      @media (max-width: 800px) {
        .bar .search input {
          font-size: 23.4px;
          border-bottom: 4px solid black;
          height: 30px; } }
  .bar .menu-item {
    margin-right: 6px;
    padding-left: 10px;
    color: inherit;
    text-decoration: none; }
    .bar .menu-item:first-child {
      padding-left: 10px; }
    .bar .menu-item.dummy {
      pointer-events: none; }
    .bar .menu-item:hover {
      color: white;
      cursor: pointer; }
    @media (max-width: 800px) {
      .bar .menu-item {
        margin-right: 0px;
        padding-left: 6px; }
        .bar .menu-item:hover {
          color: inherit; } }
    .bar .menu-item.selected {
      color: white; }
    .bar .menu-item:active {
      color: white; }

.slider {
  width: calc(100vw - 50px); }
  @media (max-width: 800px) {
    .slider {
      width: calc(100vw - 50px); } }</style>
